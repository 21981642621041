.container {
  height: 100%;

  .headerImg {
    width: 100%;
    background: url("../../assets/Images/ProductsCover.jpg") center center
      no-repeat;
    height: 300px;
    background-size: cover;

    h1 {
      text-transform: uppercase;
      text-align: center;
      color: white;
      width: 100%;
      float: left;
      margin-top: 120px;
      font-size: 44px;
      transition-delay: 0.3s;
    }
  }

  .productDetailContainer {
    padding: 0px 5%;
    margin-top: 20px;
    padding-bottom: 50px;

    .contentContainer {
      width: 100%;
      margin-bottom: 50px;
      margin-top: 20px;
      .header {
        text-decoration: underline;
        text-align: center;
        text-transform: uppercase;
      }
      .content {
        width: 100%;
        text-align: justify;
        padding: 0px 5%;
        font-size: 18px;
      }
    }
  }
}
