.app {
  .bodyRow {
    padding-top: 60px;
    margin: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .enquiryNow {
    position: fixed;
    background: #b0843f;
    bottom: 25%;
    padding: 10px 20px;
    right: -15px;
    transform: rotate(-90deg) translate(0%, 60%) !important;
    cursor: pointer;
    font-weight: 700;
    color: white;
    border-radius: 10px 10px 0px 0px;

    &:hover{
      background: #196173;
    }
  }
}
