.container {
  color: white;
  position: fixed;
  width: 100%;
  margin: 0px !important;
  height: 60px;
  padding: 10px;
  z-index: 999999;

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: white;
  }

  .appLogo {
    float: left;
    height: 100%;
    position: absolute;
    left: 50px;
    cursor: pointer;

    img {
      width: 70px;
    }
  }

  .eclipseMenu {
    z-index: 99999;
    position: relative;
    float: left;
    text-align: center;
    height: 100%;
    margin-right: 20px;

    .icon {
      color: #196173;
    }
  }

  .eclipseMenuContainer {
    position: fixed;
    top: 0px;
    background: white;
    height: 100%;
    left: 0px;
    width: 80%;
    box-shadow: 2px 0px 10px white;

    .menuBoxSeperater {
      top: 60px;
      position: absolute;
      color: #196173;
      border-top: 3px solid;
      width: 100%;
      box-shadow: 2px 0px 10px #196173;
      z-index: 9999999;
    }

    .menuBox {
      top: 62px;
      position: absolute;
      width: 100%;
      color: #196173;

      div {
        padding: 10px;
        padding: 10px 10px;
        border-bottom: 1px solid;
        cursor: pointer;

        &.selected {
          background: linear-gradient(190deg, #b0843f 50%, transparent);
          font-weight: 700;
        }
      }
    }
  }

  .menuRow {
    color: #196173;
    height: 100%;
    padding: 8px;
    font-weight: 700;
    float: left;
    height: 100%;
    float: right;

    .menuItem {
      width: 120px !important;
      text-align: center;
      cursor: pointer;
      z-index: 999;
      float: left;
      position: relative;

      &:hover,
      &.selected {
        text-decoration: underline;
        color: #b0843f;
        text-underline-offset: 5px;
      }
    }
  }

  @media (max-width: 768px) {
    .eclipseMenu,
    .eclipseMenuContainer {
      display: block;
    }

    .menuRow {
      display: none;
    }
  }

  @media (min-width: 769px) {
    .eclipseMenu,
    .eclipseMenuContainer {
      display: none;
    }

    .menuRow {
      display: block;
    }
  }
}
