.container {
  height: 100%;

  .map {
    margin: 0px;
    padding: 0px;
    .mapLocation {
      height: 100%;
      width: 100%;

      iframe {
        height: 100%;
      }
    }
  }

  .detailsContainer {
    padding: 0px 5%;
    margin-top: -40px;
    margin-bottom: 50px;
    .details {
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      min-height: 200px;
      box-shadow: 0 3px 10px rgb(0 0 0 / 1);
      color: white;
      padding: 10px;
      border-radius: 10px;
      .contactDetailsContainer {
        font-family: monospace;
        margin-bottom: 30px;

        .contactDetailsHeader {
          text-align: center;
          font-family: fantasy;
          font-weight: 700;
          margin: -10px;
          border-radius: 10px 0px 10px 0px;
          background: #196173;
          color: #b0843f;
          box-shadow: 0 3px 10px rgb(0 0 0 / 1);
          padding: 10px 0px;
        }

        .icon {
          color: black;
          float: left;
          margin-right: 10px;
          margin-top: 3px;
        }

        .header {
          font-weight: 700;
          color: black;
          margin-top: 20px;
        }

        .emailHeader {
          color: black;
          font-weight: 700;
        }

        .detailTxt {
          font-weight: 100;
          color: white !important;
        }
      }
      .inquiryContainer {
        .inquiryHeader {
          text-align: center;
          font-family: fantasy;
          font-weight: 700;
          margin: -10px;
          border-radius: 10px 0px 10px 0px;
          background: #196173;
          color: #b0843f;
          margin-bottom: 20px;
          box-shadow: 0 3px 10px rgb(0 0 0 / 1);
          padding: 10px 0px;
        }
      }
    }
  }
}
