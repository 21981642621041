.container {
  height: 100%;

  .headerImg {
    width: 100%;
    background: url("../../assets/Images/AboutUs.jpg") center center no-repeat;
    height: 300px;
    background-size: cover;
  }

  .rowContainer {
    padding: 0px 5%;
    margin-bottom: 10px;
    text-align: center;

    .contentContainer {
      width: 100%;
      margin-top: 20px;
      .header {
        text-decoration: underline;
        text-align: center;
        text-transform: uppercase;
        float: left;
        display: inline-flex;
        width: 100%;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .missionIcon {
          float: left;
          width: 30px;
          margin-right: 10px;
        }
        span {
          float: left;
          text-decoration: underline;
        }
      }
      .content {
        width: 100%;
        text-align: center;
        padding: 0px 5%;
        font-size: 18px;
        text-align: justify;

        .visionBullet {
          li {
            padding: 10px 30px;
            list-style-type: decimal;
          }
        }
      }
    }
  }

  .aboutUsImages {
    width: 70%;
    margin: 0% 15%;
    margin-bottom: 20px;
  }
}
