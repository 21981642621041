.productBoxContainer {
  margin-bottom: 20px;

  .innerContainer {
    border: 1px solid;
    border-radius: 5px;
    padding: 20px;

    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }

    @media (max-width: 768px) {
      padding: 5px;
    }

    .titleBox {
      text-align: center;

      img {
        width: 100%;
        height: 190px;
      }

      span {
        font-weight: 900;
        font-size: 18px;
        text-decoration: underline;
        word-break: break-all;
      }
    }

    .descriptionBox {
      text-align: justify;
    }
  }
}
