.container {
  background-color: black;
  color: white;
  position: fixed;
  width: 100%;
  bottom: 0px;
  margin: 0px !important;
  padding: 3px;
  font-weight: 700;
  text-align: center;
  z-index: 999999;
}
