.container {
  margin-bottom: 15px;
  background: black;
  color: white;
  margin-bottom: 40px;
  padding: 10px;
  text-align: center;

  .icon {
    margin-right: 10px;
    margin-top: 3px;
  }

  .header {
    font-weight: 700;
    margin-top: 10px;
  }

  .detailTxt {
    font-weight: 100;
    margin-bottom: 15px;
  }

  .emailHeader {
    color: white;
    font-weight: 700;
  }

  .googleMapLink {
    margin-bottom: 15px;
  }
}
