body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit !important;
}

.required {
  color: red;
}

/****  floating-Lable style start ****/
.floating-form {
  width: 320px;
}

.floating-label {
  position: relative;
  margin-bottom: 40px;
}

.floating-input,
.floating-select {
  font-size: 14px;
  padding: 4px 4px;
  display: block;
  width: 100%;
  height: 30px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid black;
  color: var(--labelColor);
}

textarea.floating-input {
  height: 100px !important;
}

.floating-input:focus,
.floating-select:focus {
  outline: none;
  border-bottom: 2px solid black;
}

.floating-label label {
  color: var(--labelColor);
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 5px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.floating-label textarea + span + label {
  top: 75px;
}

.floating-input:focus ~ label,
.floating-input:not(:placeholder-shown) ~ label {
  top: -18px;
  font-size: 14px;
  color: black;
}

.floating-select:focus ~ label,
.floating-select:not([value=""]):valid ~ label {
  top: -18px;
  font-size: 14px;
  color: black;
}

/* Button Style*/
.btn {
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  color: #fff !important;
  transition: all 0.5s !important;
  position: relative;
  padding: 10px 20px !important;
  float: right;
  border: none !important;
}

.btn:hover {
  color: black !important;
  border-color: black !important;
}
.btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #196173;
  transition: all 0.3s;
  border-radius: 7px;
}
.btn:hover::before {
  background-color: #b0843f;
}
.btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  transform: scale(1.2, 1.2);
}
.btn:hover::after {
  opacity: 1;
  transform: scale(1, 1);
  color: black !important;
}

.btn span {
  color: white !important;
  z-index: 99999999;
  position: inherit;
}

/* Scroll Bar */
/* width */
::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Social Icons */
.social {
  position: relative;
  text-align: center;
  padding: 20px 0px;
}

.social .link {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px dashed white;
  background-clip: content-box;
  padding: 2px;
  transition: 0.5s;
  color: #d7d0be;
  font-size: 18px;
  margin-bottom: 10px;
}

.social .link span {
  display: block;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.social .link:hover {
  padding: 20px;
  color: white;
  margin-left: -20px;
  transform: translateX(10px) rotate(360deg);
}

.social .link.google-plus {
  background-color: tomato;
  color: white;
}

.social .link.instagram {
  color: white;
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
}

.social .link.facebook {
  background-color: #3b5998;
  color: white;
}

.social .link.linkedin {
  background-color: #0a66c2;
  color: white;
}

.social .link.whatsapp {
  background-color: #57dd89;
  color: white;
}

.ComingSoon {
  width: 100%;
}

.loaderRow {
  text-align: center;
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-between;
  align-items: flex-start;
}

.loaderImage {
  width: 100%;
  max-width: 200px;
}
