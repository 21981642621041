.container {
  font-size: 18px;
  text-align: justify;

  .productImageContainer {
    width: 100%;
    float: left;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: space-around;
    margin-bottom: 10px;
    .productImg {
      float: left;
      width: 200px;
      margin-right: 30px;
    }
  }
}
