.socialMediaContainer {
  position: fixed;
  bottom: 30px;
  right: 10px;

  .container {
    .menusBox {
      width: 70px;
      min-height: 30px;
      text-align: center;
      background: black;
      border-radius: 10px;
      color: white;
      z-index: 999999;
      position: relative;
    }

    .icon {
      font-size: 44px;
      width: 30px;
      background: black;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      height: 30px;
      margin-top: -19px;
      margin-left: 20px;
      border-radius: 10px;
    }
  }

  img {
    width: 75px;
    height: 75px;
    cursor: pointer;
  }
}
